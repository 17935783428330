import React, { useCallback, useMemo, useState } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import Link from '@mui/material/Link'
import MuiModal from '@mui/material/Modal'
import Slide from '@mui/material/Slide'
import Tooltip from '@mui/material/Tooltip'
import { diff as deepDiff } from 'deep-object-diff'
import pick from 'lodash/pick'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './BBCLogsDetailsModal.module.scss'

import { ReactComponent as CloseIcon } from '@assets/images/modal-close-icon.svg'
import { ReactComponent as NextIcon } from '@assets/images/next-icon.svg'
import { ReactComponent as EmptyIcon } from '@assets/images/no-notes-icon.svg'
import {
  BBC_LOG_FIELDS_TO_COMPARE,
  BBC_LOG_ITEM_DEFAULT,
  BBCLogAction,
  BBCLogType,
  IBBCLog,
  IBBCLogCreditorClient,
  IBBCLogCustomRule,
  IBBCLogDebtorClientEligibility,
  IBBCLogInventoryIneligibilityCategories,
  IBBCLogMasterInventory,
  IBBCLogReserve,
} from '@common/interfaces/bbcLog'
import { BBCLogsItemContent } from '../BBCLogsItem/BBCLogsItem'
import TableContainer from '../../Common/TableContainer'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableBody from '../../Common/TableBody'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import {
  formatDate,
  formatPercent,
  formatPrice,
  handleStopPropagation,
} from '../../../helpers/helpers'
import { textToTitleCase } from '@common/helpers/helpers'
import {
  INVENTORY_FIELD_TO_LABEL,
  INVENTORY_INELIGIBILITY_FIELDS,
  ReserveKind,
} from '@common/interfaces/bbc'
import { FIELDS_LABELS } from '../../ReservesTable/constants'
import { ROUTES } from '../../../constants/routes'
import Note from '../../Notes/Note'
import AddComment from '../../Notes/AddComment'

const FIELD_LABELS = {
  type: 'Type',
  brandCategory: 'Category',
  eligibility: 'Eligibility setting',
  isCanadaMexicoLocation: 'Cad/Mex',
  creditLimits: 'Credit Limits',
  concentrationLimit: 'Concentration limit',
  field: 'Field',
  label: 'Label',
  operator: 'Operator',
  value: 'Value',
  overrides: 'Overrides',
  values: 'Value',
  category: 'Category',
  eligible: 'Eligible',
  amount: 'Amount',
  kind: 'Kind',
  percent: 'Percent',
  priorityPayable: 'Priority payables threshold',
  notes: 'Notes',
  ineligibleReason: 'Reason',
}

interface IProps {
  bbcLog: IBBCLog
  handleClose: () => void
}

const BBCLogsDetailsModalTableValue = ({
  field,
  value,
  type,
}: {
  field: string
  value: any
  type: BBCLogType
}) => {
  switch (field) {
    case 'isCanadaMexicoLocation':
      return <>{value ? 'Yes' : 'No'}</>
    case 'type':
    case 'brandCategory':
    case 'label':
    case 'operator':
    case 'value':
    case 'kind':
    case 'priorityPayable':
    case 'notes':
    case 'ineligibleReason':
      return <>{value || '-'}</>
    case 'eligible':
      return <>{value === null ? '-' : value ? 'Yes' : 'No'}</>
    case 'category':
    case 'field':
      if (type === BBCLogType.AREligibility) {
        return <>{textToTitleCase(value || '-')}</>
      }
      return <>{FIELDS_LABELS[value] || INVENTORY_FIELD_TO_LABEL[value] || '-'}</>
    case 'amount':
    case 'creditLimits':
      return <>${formatPrice(value)}</>
    case 'concentrationLimit':
      return <>{formatPercent(value)}</>
    case 'overrides':
    case 'values':
      return (
        <Tooltip title={(value || []).join(', ')} placement="top">
          <span className={styles.modalBodyDetailsLongValue}>{(value || []).join(', ')}</span>
        </Tooltip>
      )
    case 'percent':
      return <>{value}%</>
    default:
      return null
  }
}

const BBCLogsDetailsModalTableRowDiff = ({
  entity,
  type,
  oldItem,
  newItem,
}: {
  entity: string
  type: BBCLogType
  oldItem: any
  newItem: any
}) => {
  const changes = useMemo(() => {
    const diff: any = pick(
      deepDiff(oldItem || BBC_LOG_ITEM_DEFAULT[type], newItem || {}),
      BBC_LOG_FIELDS_TO_COMPARE[type],
    )

    if (newItem?.eligible === null) {
      delete diff.eligible
    }

    return Object.keys(diff).reduce(
      (result, field) => [
        ...result,
        {
          field,
          oldValue: (oldItem || BBC_LOG_ITEM_DEFAULT[type] || {})[field],
          newValue: newItem?.[field],
        },
      ],
      [],
    )
  }, [oldItem, newItem, type])

  return (
    <React.Fragment>
      {changes.map(({ field, oldValue, newValue }) => (
        <TableRow key={field}>
          <TableCell className={genericSs.tableTextLeft}>
            <Tooltip title={entity} placement="top">
              <span>{entity}</span>
            </Tooltip>
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>{FIELD_LABELS[field]}</TableCell>
          <TableCell className={genericSs.tableTextLeft}>
            <BBCLogsDetailsModalTableValue field={field} value={oldValue} type={type} />
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>
            <BBCLogsDetailsModalTableValue field={field} value={newValue} type={type} />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  )
}

const BBCLogsDetailsModalTableRowAREligibility = ({
  debtorClientEligibility,
}: {
  debtorClientEligibility: IBBCLogDebtorClientEligibility
}) => {
  return (
    <BBCLogsDetailsModalTableRowDiff
      entity={debtorClientEligibility.newItem?.entityInfo?.name}
      type={BBCLogType.AREligibility}
      oldItem={debtorClientEligibility.oldItem}
      newItem={debtorClientEligibility.newItem}
    />
  )
}

const BBCLogsDetailsModalTableRowCustomRule = ({
  customRule,
}: {
  customRule: IBBCLogCustomRule
}) => {
  switch (customRule.action) {
    case BBCLogAction.add:
      return (
        <TableRow>
          <TableCell className={genericSs.tableTextLeft}>
            <Tooltip title={customRule.newRule?.label} placement="top">
              <span>{customRule.newRule?.label}</span>
            </Tooltip>
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>Created</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      )
    case BBCLogAction.delete:
      return (
        <TableRow>
          <TableCell className={genericSs.tableTextLeft}>
            <Tooltip title={customRule.oldRule?.label} placement="top">
              <span>{customRule.oldRule?.label}</span>
            </Tooltip>
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>Deleted</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      )
    case BBCLogAction.update:
      return (
        <BBCLogsDetailsModalTableRowDiff
          entity={customRule.newRule?.label}
          type={BBCLogType.CustomRule}
          oldItem={customRule.oldRule}
          newItem={customRule.newRule}
        />
      )
    default:
      return null
  }
}

const BBCLogsDetailsModalTableRowInventoryEligibility = ({
  inventoryIneligibilityCategory,
}: {
  inventoryIneligibilityCategory: IBBCLogInventoryIneligibilityCategories
}) => {
  const entity = useMemo(
    () =>
      [
        Object.values(INVENTORY_INELIGIBILITY_FIELDS).find(
          (item) => item.category === inventoryIneligibilityCategory.newItem?.category,
        )?.reason,
        inventoryIneligibilityCategory.newItem?.value,
      ]
        .filter(Boolean)
        .join(' - '),
    [inventoryIneligibilityCategory],
  )

  return (
    <BBCLogsDetailsModalTableRowDiff
      entity={entity}
      type={BBCLogType.InventoryEligibility}
      oldItem={inventoryIneligibilityCategory.oldItem}
      newItem={inventoryIneligibilityCategory.newItem}
    />
  )
}

const BBCLogsDetailsModalTableRowInventoryMapping = ({
  masterInventory,
}: {
  masterInventory: IBBCLogMasterInventory
}) => {
  return (
    <BBCLogsDetailsModalTableRowDiff
      entity={masterInventory.newItem?.sku}
      type={BBCLogType.InventoryMapping}
      oldItem={masterInventory.oldItem}
      newItem={masterInventory.newItem}
    />
  )
}

const BBCLogsDetailsModalTableReserve = ({ reserve }: { reserve: IBBCLogReserve }) => {
  const entity = useMemo(() => {
    const item = reserve.newReserve || reserve.oldReserve

    if (!item) {
      return ''
    }

    return [
      item.kind === ReserveKind.Absolute ? '$' + formatPrice(item.amount) : item.percent + '%',
      item.type,
      'Reserve',
    ]
      .filter(Boolean)
      .join(' ')
  }, [reserve])

  switch (reserve.action) {
    case BBCLogAction.add:
      return (
        <TableRow>
          <TableCell className={genericSs.tableTextLeft}>
            <Tooltip title={entity} placement="top">
              <span>{entity}</span>
            </Tooltip>
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>Created</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      )
    case BBCLogAction.delete:
      return (
        <TableRow>
          <TableCell className={genericSs.tableTextLeft}>
            <Tooltip title={entity} placement="top">
              <span>{entity}</span>
            </Tooltip>
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>Deleted</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      )
    case BBCLogAction.update:
      return (
        <BBCLogsDetailsModalTableRowDiff
          entity={entity}
          type={BBCLogType.Reserve}
          oldItem={reserve.oldReserve}
          newItem={reserve.newReserve}
        />
      )
    default:
      return null
  }
}

const BBCLogsDetailsModalTableRowAPEligibility = ({
  creditorClient,
}: {
  creditorClient: IBBCLogCreditorClient
}) => {
  return (
    <BBCLogsDetailsModalTableRowDiff
      entity={creditorClient.newItem?.entityInfo?.name}
      type={BBCLogType.APEligibility}
      oldItem={creditorClient.oldItem}
      newItem={creditorClient.newItem}
    />
  )
}

export const BBCLogsDetailsModalTableBody = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  return (
    <TableBody>
      {bbcLog.debtorClientEligibility.map((debtorClientEligibility) => (
        <BBCLogsDetailsModalTableRowAREligibility
          key={debtorClientEligibility.id}
          debtorClientEligibility={debtorClientEligibility}
        />
      ))}
      {bbcLog.customRules.map((customRule) => (
        <BBCLogsDetailsModalTableRowCustomRule key={customRule.id} customRule={customRule} />
      ))}
      {bbcLog.inventoryIneligibilityCategories.map((inventoryIneligibilityCategory) => (
        <BBCLogsDetailsModalTableRowInventoryEligibility
          key={inventoryIneligibilityCategory.id}
          inventoryIneligibilityCategory={inventoryIneligibilityCategory}
        />
      ))}
      {bbcLog.masterInventory.map((masterInventory) => (
        <BBCLogsDetailsModalTableRowInventoryMapping
          key={masterInventory.id}
          masterInventory={masterInventory}
        />
      ))}
      {bbcLog.reserves.map((reserve) => (
        <BBCLogsDetailsModalTableReserve key={reserve.id} reserve={reserve} />
      ))}
      {bbcLog.creditorClients.map((creditorClient) => (
        <BBCLogsDetailsModalTableRowAPEligibility
          key={creditorClient.id}
          creditorClient={creditorClient}
        />
      ))}
    </TableBody>
  )
}

const BBCLogsDetailsModal = ({ bbcLog, handleClose }: IProps) => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleIsOpened = useCallback(() => {
    setIsOpened((isOpened) => !isOpened)
  }, [])

  const clientLink = useMemo(
    () =>
      bbcLog.clientInfo ? generatePath(ROUTES.CLIENT_PAGE, { id: bbcLog.clientInfo.id }) : null,
    [bbcLog],
  )

  const bbcLink = useMemo(
    () =>
      bbcLog.borrowingBaseId
        ? generatePath(ROUTES.BBC_SUMMARY, { id: bbcLog.borrowingBaseId })
        : null,
    [bbcLog],
  )

  return (
    <MuiModal
      open
      onClose={handleClose}
      disableEnforceFocus
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300,
          className: styles.modalBackdrop,
        },
      }}
      className={styles.modalWrapper}
    >
      <Slide
        direction="left"
        in
        timeout={300}
        mountOnEnter
        unmountOnExit
        onEnter={toggleIsOpened}
        onExit={toggleIsOpened}
      >
        <div className={cn(styles.modal, { [styles.modalOpened]: isOpened })}>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>{bbcLog.type}</div>
            <CloseIcon className={styles.modalClose} onClick={handleClose} />
          </div>
          <div className={styles.modalBreadcrumbs}>
            <Link
              component={RouterLink}
              to={clientLink}
              className={styles.modalBreadcrumbItem}
              onClick={handleStopPropagation}
            >
              {bbcLog.clientName}
            </Link>
            {bbcLog.borrowingBase && (
              <React.Fragment>
                <NextIcon className={styles.modalBreadcrumbItemSeparator} />
                <Link
                  component={RouterLink}
                  to={bbcLink}
                  className={styles.modalBreadcrumbItem}
                  onClick={handleStopPropagation}
                >
                  BBC {formatDate(bbcLog.borrowingBase.recordDate)}
                </Link>
              </React.Fragment>
            )}
          </div>
          <div className={styles.modalBody}>
            <div className={styles.modalBodySummary}>
              <BBCLogsItemContent bbcLog={bbcLog} />
            </div>
            <div className={styles.modalBodyDetails}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>Entity</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Field</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>Old Value</TableCell>
                      <TableCell className={genericSs.tableTextLeft}>New Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <BBCLogsDetailsModalTableBody bbcLog={bbcLog} />
                </Table>
              </TableContainer>
            </div>
            <div className={styles.modalBodyReplies}>
              <div className={styles.modalBodyRepliesLabel}>Comments</div>

              {bbcLog.notes?.[0]?.children?.length > 0 ? (
                <div className={styles.modalBodyRepliesChildContainer}>
                  {bbcLog.notes?.[0]?.children.map((note) => (
                    <Note key={note.id} note={note} isChild />
                  ))}
                </div>
              ) : (
                <div className={styles.modalBodyRepliesEmptyContainer}>
                  <EmptyIcon />
                  <div className={styles.modalBodyRepliesEmptyContainerText}>No comments</div>
                </div>
              )}

              {bbcLog.notes?.[0] && (
                <AddComment
                  parent={bbcLog.notes?.[0]}
                  className={styles.modalBodyRepliesReply}
                  noteEditorFlex
                />
              )}
            </div>
          </div>
        </div>
      </Slide>
    </MuiModal>
  )
}

export default BBCLogsDetailsModal

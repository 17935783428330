import React, { useMemo } from 'react'
import { diff as deepDiff } from 'deep-object-diff'
import pick from 'lodash/pick'

import styles from './BBCLogsItem.module.scss'

import {
  BBC_LOG_FIELDS_TO_COMPARE,
  BBC_LOG_ITEM_DEFAULT,
  BBCLogType,
  IBBCLog,
} from '@common/interfaces/bbcLog'
import BBCLogsItemContentUsers from './BBCLogsItemContentUsers'

const FIELD_LABELS = {
  type: 'type',
  brandCategory: 'category',
  eligibility: 'eligibility setting',
}

const BBCLogsItemContentInventoryEligibility = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  const updates = useMemo(() => {
    const changes = {}
    bbcLog.masterInventory
      .map(({ oldItem, newItem }) => ({
        ...pick(
          deepDiff(oldItem || BBC_LOG_ITEM_DEFAULT[BBCLogType.InventoryMapping], newItem || {}),
          BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.InventoryMapping],
        ),
      }))
      .map((diff: any) => {
        if (diff.eligible === null) {
          delete diff.eligible
        }

        return diff
      })
      .forEach((diff) => {
        Object.keys(diff).forEach((field) => {
          if (!changes[field]) {
            changes[field] = 0
          }
          changes[field]++
        })
      })

    return changes
  }, [bbcLog])

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      <b>updated</b>&nbsp;
      {Object.keys(updates).map((field, index) => (
        <React.Fragment key={field}>
          <b>{FIELD_LABELS[field]}</b> {field !== 'eligibility' && 'mapping'} for&nbsp;
          <b>{updates[field]} SKUs</b>
          {index === Object.keys(updates).length - 2
            ? ' and '
            : index !== Object.keys(updates).length - 1
            ? ', '
            : ''}
        </React.Fragment>
      ))}
    </div>
  )
}

export default BBCLogsItemContentInventoryEligibility
